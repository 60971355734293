.#{$prefix}textarea {
  //position: relative;
  box-sizing: border-box;
  label {
    display: inline-block;
    margin-bottom: v(spacing-scale-baseh);
    padding-bottom: v(spacing-scale-half);
  }
  textarea {
    @include textarea;
    @include textarea_state;
    @include textarea_density;
    &:not(:disabled) {
      @include hover;

      &:focus,
      &:focus-visible,
      &.focus-visible {
        @include focus-shadow;
      }
    }
    &.disabled,
    &[disabled] {
      background-color: v(color-secondary-03);
      ~ .feedback {
        align-items: center;
        background: var(--color-warning);
        color: var(--color-secondary-08);
        display: inline-flex;
        height: 35px;
        margin-bottom: var(--spacing-scale-base);
        margin-top: 7px;
        padding: 5px;
      }
      ~ .help {
        display: none;
      }
    }
  }
}
