@import "../../partial/scss/base";

@mixin card {
  @include card-tokens;
  @include card-base;
  @include card-back;
}

@mixin card-tokens {
  --card-background: var(--color-secondary-01);
  --card-back-background: var(--color-secondary-07);
  --card-margin: var(--spacing-scale-2x);
  --card-padding: var(--spacing-scale-2x);
  --card-shadow: var(--surface-shadow-sm);
}

@mixin card-base {
  background: var(--card-background);
  box-shadow: var(--card-shadow);
  color: var(--card-color);
  margin-bottom: var(--card-margin);
  .card-content,
  .front .content,
  .back .content {
    padding: var(--card-padding);
    *:last-child {
      margin-bottom: 0;
    }
  }
  .front .header,
  .back .header,
  .card-header {
    padding: var(--card-padding) var(--card-padding) 0;
  }
  .front .footer,
  .back .footer,
  .card-footer {
    padding: 0 var(--card-padding) var(--card-padding);
  }
}

@mixin card-back {
  .back {
    background: var(--card-back-background);
    color: var(--color-secondary-01);
  }
}

.#{$prefix}card {
  @include card;
  // box-shadow: 0 3px 6px rgba(v(rgb-secondary-09), 0.16);
  // margin-bottom: v(spacing-scale-2x);
  // > .front:not([hidden]),
  // > .back:not([hidden]) {
  //   background: v(bg-color);
  //   color: v(text-color);
  //   display: flex;
  //   flex-flow: column;
  //   > .header,
  //   > .content,
  //   > .footer {
  //     padding: v(spacing-scale-2x);
  //     &:empty {
  //       padding: 0;
  //     }
  //     *:last-child {
  //       margin-bottom: 0;
  //     }
  //   }
  //   > .content {
  //     flex: 1;
  //   }
  //   > .header + .content,
  //   > .content + .footer {
  //     padding-top: 0;
  //   }
  // }
  // > .back {
  //   --bg-color: var(--color-secondary-07);
  //   --text-color: var(--color-secondary-01);
  // }
  // &[flipped] {
  //   > .front,
  //   > .back {
  //     overflow: hidden;
  //     transition: opacity 600ms ease;
  //   }
  // }
  // &[flipped="on"] {
  //   > .front {
  //     height: 0;
  //     opacity: 0;
  //   }
  //   .br-button.flip {
  //     color: v(button-color);
  //     &:hover {
  //       color: v(color-secondary-01);
  //     }
  //   }
  // }
  // &[flipped="off"] {
  //   > .back {
  //     height: 0;
  //     opacity: 0;
  //   }
  // }
  // &[expanded] {
  //   .expanded {
  //     overflow: hidden;
  //   }
  // }
  // &[expanded="on"] {
  //   .expanded {
  //     height: 100%;
  //     opacity: 1;
  //   }
  // }
  // &[expanded="off"] {
  //   .expanded {
  //     opacity: 0;
  //     height: 0;
  //     z-index: -1;
  //   }
  // }
  .#{$prefix}list {
    .#{$prefix}item {
      // width: auto;
      white-space: normal;
    }
  }
  .#{$prefix}button {
    &[aria-expanded="true"] {
      transform: rotate(180deg);
    }
  }
}
