@mixin item-component {
  @include item-tokens;
  @include item-base;
  @include item-checkbox;
  @include item-colors;
  @include item-states;
}

@mixin item-tokens {
  --item-background: var(--bg-color);
  --item-color: var(--text-color);
  --item-font-weight: var(--font-weight);
  --item-padding-x: var(--spacing-scale-2x);
  --item-padding-y: var(--spacing-scale-base);
  --item-padding: var(--item-padding-y) var(--item-padding-x);
}

@mixin item-base {
  background: var(--item-background);
  border: 0;
  color: var(--item-color);
  display: block;
  font-weight: var(--item-font-weight);
  padding: var(--item-padding);
  text-align: left;
  width: 100%;
  // Regras para Grid interna
  .row {
    margin-left: calc(-1 * var(--spacing-scale-base));
    margin-right: calc(-1 * var(--spacing-scale-base));
    .col,
    [class*="col-"] {
      padding-left: v(spacing-scale-base);
      padding-right: v(spacing-scale-base);
    }
  }
  // Fix para floats internos
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}

@mixin item-checkbox {
  .#{$prefix}checkbox,
  .#{$prefix}radio {
    input {
      + label {
        // FIXME: Retirar esse important. Vai ser necessário alterar o CSS do rádio por conta da especificidade
        color: var(--text-color) !important;
      }
      &:hover {
        + label::before {
          // FIXME: Retirar esse important. Vai ser necessário alterar o CSS do rádio por conta da especificidade
          background-image: none !important;
        }
      }
    }
  }
}

@mixin item-colors {
  @each $key in map-keys($br-colors) {
    &.#{$key} {
      @include colorize($key);
    }
  }
}

@mixin item-states {
  // Selecionado
  &.selected {
    --bg-color: var(--selected);
    @include dark-mode;
  }
  // Ativo
  &.active {
    @include active;
  }
}

.#{$prefix}item {
  --interactive-rgb: var(--rgb-secondary-08);
  @include item-component;
  @include focus;
  .#{$prefix}radio,
  .#{$prefix}checkbox {
    input[type]:focus:checked,
    input[type]:focus {
      + label::before {
        outline: none;
      }
    }
    label {
      width: 100%;
    }
  }
}

// Item aplicado em link/botão
a.#{$prefix}item,
button.#{$prefix}item,
.#{$prefix}item[data-toggle] {
  --interactive-rgb: var(--rgb-primary-default);
  --item-color: var(--interactive);
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  @include hover;
}

.#{$prefix}divider:first-child{
  border-top: none;
}
