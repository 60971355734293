.#{$prefix}cookiebar {
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000000;
  @include media-breakpoint-up(sm) {
    .actions {
      .br-button {
        &.secondary {
          margin-bottom: v(spacing-scale-2xh);
          margin-right: v(spacing-scale-5x);
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .actions {
      .br-button {
        &.secondary {
          margin-bottom: v(spacing-scale-2xh);
          margin-right: 0;
        }
      }
    }
    .br-modal-header {
      .br-modal-title p {
        font-size: v(font-size-scale-up-02);
      }
    }
    .group-description {
      font-size: v(font-size-scale-down-01);
    }
    .br-switch {
      label {
        font-size: v(font-size-scale-down-01);
      }
      .switch-data {
        &::before {
          font-size: v(font-size-scale-down-01);
        }
      }
    }
    .group-name,
    .group-size {
      font-size: v(font-size-scale-down-01);
    }
  }
  &.default {
    top: unset;
    .entry-content {
      display: none;
    }
    .info-text {
      display: block;
    }
    .main-content {
      display: none;
    }
    .parallel-content {
      display: none;
    }
    .br-modal {
      > .br-card {
        --card-shadow: var(--surface-offset-none)
          calc(var(--surface-offset-md) * -1) var(--surface-blur-lg)
          rgba(var(--rgb-secondary-09), var(--surface-opacity-xs));
      }
      .br-modal-body {
        overflow-y: unset;
      }
    }
  }
  .entry-text {
    margin-bottom: 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .info-text {
    display: none;
    line-height: v(font-lineheight-medium);
  }
  .br-modal-header {
    .br-modal-title {
      font-size: v(font-size-scale-up-06);
      margin-bottom: v(spacing-scale-3xh);
      max-height: unset;
      width: auto;
    }
    .last-update {
      font-size: v(font-size-scale-up-01);
      font-weight: v(font-weight-semi-bold);
    }
  }
  .br-modal-body {
    flex: 1;
    overflow-y: scroll;
    &:focus {
      outline: none;
    }
  }
  .group-name,
  .group-size {
    color: v(color-primary-default);
  }
  .group-info,
  .cookie-info {
    .br-switch {
      padding: v(spacing-scale-half);
      width: 120px;
    }
  }
  .always-active {
    color: v(color-highlight);
    font-weight: v(font-weight-semi-bold);
  }
  .cookie-info {
    .br-card {
      margin-bottom: var(--card-margin);
    }
    .cookie-term {
      font-weight: v(font-weight-semi-bold);
    }
  }
  .notes {
    p:first-child {
      font-weight: v(font-weight-semi-bold);
    }
  }
}
