@mixin avatar {
  @include avatar-tokens;
  @include avatar-base;
  @include avatar-sizes;
  @include avatar-change;
}

@mixin avatar-tokens {
  --avatar-background: var(--color-primary-pastel-02);
  --avatar-color: var(--color-primary-pastel-01);
  --avatar-size: var(--avatar-small);
  --avatar-font-weight: var(--font-weight-bold);
  --avatar-icon-size: var(--avatar-icon-small);
  --avatar-text-size: var(--avatar-text-small);
  --avatar-small: 40px;
  --avatar-medium: 100px;
  --avatar-large: 160px;
  --avatar-icon-small: var(--icon-size-2x);
  --avatar-icon-medium: var(--icon-size-5x);
  --avatar-icon-large: var(--icon-size-8x);
  --avatar-text-small: var(--font-size-scale-up-03);
  --avatar-text-medium: var(--font-size-scale-up-07);
  --avatar-text-large: var(--font-size-scale-up-11);
}

@mixin avatar-base {
  display: inline-block;
  vertical-align: middle;
  .image {
    background-color: var(--avatar-background);
    border-radius: 50%;
    color: var(--avatar-color);
    display: inline-block;
    font-size: var(--avatar-text-size);
    font-weight: var(--avatar-font-weight);
    overflow: hidden;
    text-align: center;
    height: var(--avatar-size);
    width: var(--avatar-size);
    img {
      height: var(--avatar-size);
      width: var(--avatar-size);
      vertical-align: baseline;
    }
    @include icon {
      --icon-size: var(--avatar-icon-size);
      margin-top: 0.25em;
    }
  }
}

@mixin avatar-sizes {
  @each $size in small, medium, large {
    &.is-#{$size},
    &.#{$size},
    &[#{$size}] {
      --avatar-size: var(--avatar-#{$size});
      --avatar-icon-size: var(--avatar-icon-#{$size});
      --avatar-text-size: var(--avatar-text-#{$size});
    }
  }
}

@mixin avatar-change {
  &-action {
    background: transparent;
    border: 0;
    @include hover;
    @include focus;
  }
}

.#{$prefix}avatar {
  @include avatar;
}
