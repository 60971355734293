.#{$prefix}footer {
  --text-color: var(--color-secondary-01);
  @include dark-mode;
  background: v(color-primary-darken-02);
  color: v(color-secondary-01);
  display: block;

  @include media-breakpoint-up(md) {
    .#{$prefix}list .#{$prefix}item .support {
      display: none;
      pointer-events: none;
    }
  }

  .logo {
    padding: v(spacing-scale-6x) 0 v(spacing-scale-4x);
    img {
      max-height: 48px;
      max-width: 180px;
    }
  }

  .assigns {
    img {
      max-height: 46px;
      max-width: 180px;
    }
  }

  .social-network {
    border: 0;
  }

  .#{$prefix}divider {
    --divider-color: var(--divider-dark);
  }

  &[inverted],
  &.inverted {
    --interactive-rgb: var(--rgb-primary-default);
    --hover: var(--surface-opacity-xs, 0.16);
    --focus-color: var(--color-support-03);
    --text-color: var(--color-primary-darken-02);
    background: v(color-secondary-01);
    border-top: 1px solid v(color-secondary-04);
    color: v(color-primary-darken-02);
    .#{$prefix}item {
      --item-background: var(--color-secondary-01);
      --item-color: var(--color-primary-darken-02);
      &.header {
        .content,
        .support {
          color: var(--item-color);
        }
      }
    }

    .#{$prefix}divider {
      --divider-color: var(--divider-light);
    }
  }

  .#{$prefix}item {
    --item-background: var(--color-primary-darken-02);
    --item-header-color: var(--color-secondary-01);
    --item-color: var(--color-primary-pastel-01);
    align-items: center;
    display: flex;
    @include hover;
    &.header {
      color: var(--item-header-color);
    }
    &.active {
      background: transparent;
    }
    .content {
      flex: 1;
    }
  }

  .#{$prefix}list {
    &.horizontal {
      display: flex;
      flex-wrap: wrap;
      @include media-breakpoint-up(md) {
        .#{$prefix}item {
          .support:last-child {
            display: none;
            pointer-events: none;
          }
          .content {
            flex: 1;
          }
        }
        // evita collapse no breakpoint
        .#{$prefix}list {
          background-image: none;
          max-height: 200em !important;
        }
      }
      .header {
        width: 100%;
      }
      //  Para ser compatível com outros navegadores no
      //  uso máximo de 2 linhas, foram adicionados os
      //  comandos line-height e max-height.

      .#{$prefix}item,
      .#{$prefix}list .#{$prefix}item {
        height: 4em;
        .content {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          line-height: 16px;
          max-height: 32px;
          height: auto;
          overflow: hidden;
          padding: 0 v(spacing-scale-2x);
          text-overflow: ellipsis;
          font-weight: var(--font-weight-bold);
        }
      }
      .col,
      [class*="col-"] {
        flex-grow: 0;
        padding: 0;
        @include media-breakpoint-down(sm) {
          flex-basis: 100% !important;
          max-width: 100%;
          .#{$prefix}item.header.active {
            border-bottom: 1px solid var(--color-secondary-04);
            + .#{$prefix}list {
              border-bottom: 1px solid var(--color-secondary-04);
            }
          }
        }
      }
    }
  }
}
