@mixin input {
  background: var(--bg-color);
  border: $input-border-width solid $input-border-color;
  border-radius: $input-radius;
  color: v(color-secondary-07);
  display: block;
  font-size: v(font-size-scale-up-01);
  font-weight: v(font-weight-medium);
  height: var(--input-size);
  padding-left: v(spacing-scale-2x);
  padding-right: v(spacing-scale-5x);
  width: 100%;

  &::placeholder {
    color: var(--text-color);
    font-size: var(--font-size-scale-base);
    font-style: italic;
    font-weight: var(--font-weight-regular);
    opacity: 0.75;
  }

  &:not(:disabled) {
    @include hover;
    @include focus;

    &:focus,
    &:focus-visible,
    &.focus-visible {
      @include focus-shadow;
    }
  }
}

@mixin input_state {
  /*** Estados ***/
  @each $state, $color in (success, v(color-success)), (danger, v(color-danger)),
    (warning, v(color-warning)), (info, v(color-info))
  {
    &.#{$state},
    &[data-#{$state}] {
      input {
        border: 2px solid $color;
      }
      .feedback.color-#{$state} {
        align-items: center;
        background: $color;
        color: var(--color-secondary-01) !important;
        display: inline-flex;
        height: 35px;
        margin-bottom: var(--spacing-scale-base);
        padding: 5px;
      }
      @if ($state == warning) {
        .feedback.color-warning {
          color: var(--color-secondary-08) !important;
        }
      }
    }
  }
}

@mixin input_density {
  --input-small: 48px;
  --input-medium: 40px;
  --input-large: 32px;
  --input-size: var(--input-medium);

  @each $size in "small", "medium", "large" {
    &.#{$size},
    &[data-#{$size}] {
      --input-size: var(--input-#{$size});
    }
  }
}
