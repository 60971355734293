// Pesos
@each $key, $value in $text-font-weight {
  .text-#{"" + $key} {
    font-weight: $value;
  }
}

// Tamanhos
@each $key, $value in $text-font-size {
  .text-#{$key} {
    font-size: $value;
  }
}
