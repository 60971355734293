// Criar cores de fundo
@mixin bg-colors($item, $value) {
  .bg-#{$item} {
    background: var(--color-#{$item}) !important;
  }
}

// Criar cores de textos
@mixin text-colors($item, $value) {
  .text-#{$item} {
    color: v($item, $value) !important;
  }
}

// Criar cores de bordas
@mixin border-colors($item, $value) {
  .border-#{$item} {
    border-color: v($item, $value) !important;
  }
}

@mixin colorize($color) {
  --bg-color: var(--color-#{$color});
  @if $color == "primary-lighten-01" {
    @include dark-mode;
  } @else if $color == "primary-lighten-02" {
    @include dark-mode;
  } @else if $color == "primary-default" {
    @include dark-mode;
  } @else if $color == "primary-darken-01" {
    @include dark-mode;
  } @else if $color == "primary-darken-02" {
    @include dark-mode;
  } @else if $color == "secondary-06" {
    @include dark-mode;
  } @else if $color == "secondary-07" {
    @include dark-mode;
  } @else if $color == "secondary-08" {
    @include dark-mode;
  } @else if $color == "secondary-09" {
    @include dark-mode;
  } @else if $color == "highlight" {
    @include dark-mode;
  } @else if $color == "success" {
    @include dark-mode;
  } @else if $color == "danger" {
    @include dark-mode;
  } @else if $color == "info" {
    @include dark-mode;
  } @else if $color == "support-01" {
    @include dark-mode;
  } @else if $color == "support-03" {
    @include dark-mode;
  } @else if $color == "support-04" {
    @include dark-mode;
  } @else if $color == "support-07" {
    @include dark-mode;
  } @else if $color == "support-08" {
    @include dark-mode;
  } @else if $color == "facebook" {
    @include dark-mode;
  } @else if $color == "google-plus" {
    @include dark-mode;
  } @else if $color == "linkedin" {
    @include dark-mode;
  } @else if $color == "twitter" {
    @include dark-mode;
  } @else if $color == "whatsapp" {
    @include dark-mode;
  } @else if $color == "xbox" {
    @include dark-mode;
  } @else if $color == "yahoo" {
    @include dark-mode;
  } @else if $color == "youtube" {
    @include dark-mode;
  }
}
