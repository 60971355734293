:root {
  // Fonte Family
  --font-family-base: #{$font-family-base};
  // Cor hexadecimal
  @include tokens($br-colors, "color-");
  // Cor rgb
  @each $key, $value in $br-colors {
    --rgb-#{$key}: #{get-rgb($value)};
  }
  // Iconografia
  @include tokens($tokens-icons);
  // Superfície
  @include tokens($widths, "surface-");
  @include tokens($rounders, "surface-");
  @include tokens($opacities, "surface-");
  @include tokens($borders, "surface-");
  @include tokens($blurs, "surface-");
  @include tokens($offsets, "surface-");
  @include tokens($shadows, "surface-");
  --surface-overlay-scrim: #{$overlay-scrim};
  --surface-overlay-text: #{$overlay-text};

  // Tipografia
  @include tokens($tokens-font-weight);
  @include tokens($tokens-font-size);
  @include tokens($tokens-line-height);
  // Grid
  @include tokens($tokens-breakpoints);
  @include tokens($tokens-portrait);
  @include tokens($tokens-tablet);
  @include tokens($tokens-desktop);
  @include tokens($tokens-tv);
  // Alinhamento
  @include tokens($tokens-alinhamento);
  // Espaçamento
  @include tokens($tokens-spacing);
  // Estados
  --interactive: var(--color-primary-default);
  --interactive-rgb: var(--rgb-primary-default);
  --visited: var(--color-primary-darken-01);
  --disabled: var(--surface-opacity-md, 0.45);
  --hover: var(--surface-opacity-xs, 0.16);
  --hover-effect: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
  --pressed: var(--surface-opacity-md, 0.45);
  --pressed-effect: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
  --focus-style: dashed;
  --focus-color: var(--color-support-03);
  --focus-offset: var(--spacing-scale-half);
  --focus-width: var(--surface-width-lg, 4px);
  --active: var(--color-primary-darken-01);
  --selected: var(--color-primary-lighten-01);
  --on: var(--color-primary-lighten-02);
  --off: var(--color-secondary-06);
  --ondrag-border-color: var(--interactive);
  --ondrag-border-width: var(--surface-width-md, 2px);
  --ondrag-border-style: solid;
  --ondrag-shadow-offset-x: var(--surface-offset-none, 0);
  --ondrag-shadow-offset-y: var(--surface-offset-md, 3px);
  --ondrag-shadow-blur: var(--surface-blur-lg, 6px);
  --ondrag-shadow-color: var(--interactive-rgb);
  --ondrag-shadow-opacity: var(--surface-opacity-sm, 0.3);
  --ondrag-opacity: var(--surface-opacity-xl, 0.85);
  --ondrag-rotate: -5deg;
  --ondrag-cursor: grabbing;
  --draggable-icon: grip-vertical;
  --draggable-cursor: grab;
  --dropzone-border-color: var(--interactive);
  --dropzone-border-width: var(--surface-width-sm, 1px);
  --dropzone-border-style: dashed;
  --ondrop-background: var(--interactive-rgb);
  --ondrop-opacity: var(--surface-opacity-sm, 0.3);
  --ondrop-cursor: copy;
}
