@import "../../partial/scss/base";

@mixin tab {
  @include tab-tokens;
  @include tab-darkmode;
  @include tab-nav;
  @include tab-content;

}

@mixin tab-tokens {
  --tab-color: var(--color-secondary-08);
  --tab-surface: var(--color-secondary-01);
  --tab-background: var(--bg-color);
  --tab-border: var(--surface-width-lg) solid var(--interactive);
  --tab-nav-border: var(--color-secondary-04);
}

@mixin tab-darkmode {
  &.inverted {
    --tab-color: var(--color-secondary-01);
    // --tab-border:  4px solid var(--color-secondary-02)
    --tab-nav-border: var(--color-secondary-02);
    @include dark-mode;
    ul {
      .tab-item {
        &:hover {
          .name {
            --tab-background: var(--color-secondary-02);
          }
        }
        button {
          color: var(--color-secondary-01) !important;
          &:hover {
            .name {
              color: var(--color-secondary-01);
            }
          }
          &:focus {
            .name {
              color: var(--color-secondary-01);
            }
          }
        }
      }
    }
  }
}

@mixin tab-nav {
  &[data-counter="true"] {
    .tab-nav {
      ul {
        margin-bottom: v(spacing-scale-9x);
        .tab-item {
          &[not-tab="true"] {
            .name {
              color: v(color-secondary-08, $color-secondary-08);
              display: inline-block;
              font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
              font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
              line-height: v(font-size-scale-up-03, $font-size-scale-up-03);
              padding-bottom: 6px;
            }
            .outcome {
              font-size: v(font-size-scale-up-02, $font-size-scale-up-02);
              font-weight: v(font-weight-regular, $font-weight-regular);
              left: 0;
              position: absolute;
              top: 52px;
              span {
                color: var(--interactive);
                display: inline-block;
                font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
                font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
                margin-bottom: v(spacing-scale-4x);
                margin-left: v(spacing-scale-3x);
              }
            }
          }
        }
      }
    }
  }
  .tab-nav {
    overflow-x: auto;
    width: 100%;
    @include media-breakpoint-down(sm) {
      display: flex;
      overflow-x: auto;
      width: 100%;
    }
    ul {
      // border-bottom: 1px solid v(color-secondary-04, $color-secondary-04);
      border-bottom: 1px solid var(--color-secondary-04);
      display: flex;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      width: 100%;
      .tab-item {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 v(spacing-scale-3x);
        position: relative;
        white-space: nowrap;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-active {
          button {
            border-bottom: var(--tab-border);
            padding-bottom: v(spacing-scale-base);
            .name {
              // color: v(color-primary-darken-02, $color-primary-darken-02);
              color: --tab-color;
            }
          }
          .results {
            color: var(--interactive);
          }
        }
        button {
          --focus-offset: calc(var(--spacing-scale-half) * -1);
          @include focus;
          @include hover;
          background-color: transparent;
          border: none;
          border-bottom: 4px solid transparent;
          color: var(--tab-color);
          display: inline-block;
          padding: v(spacing-scale-base) v(spacing-scale-3x);
          &:hover {
            .name {
              color: var(--tab-color);
            }
          }
          &:focus {
            .name {
              color: var(--interactive);
            }
          }
          .name {
            color: var(--tab-color);
            font-size: v(font-size-scale-up-02, $font-size-scale-up-02);
            font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
            text-align: center;
            white-space: nowrap;
          }
        }
        .results {
          color: v(color-secondary-07, $color-secondary-07);
          display: flex;
          font-size: v(font-size-scale-base, $font-size-scale-base);
          font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
          justify-content: center;
          position: absolute;
          top: 60px;
        }
      }
    }
  }
}

@mixin tab-content {
  .tab-content {
    .tab-panel {
      color: var(--interactive);
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
}

@mixin tab-nav-gradiente {
  .tab-nav-right::after {
    //TODO: Atualizar o código com padrão de gradiente quando estiver pronto
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, var(--surface-opacity-none)),
      rgba(var(--rgb-secondary-09), 0.2)
    );
    content: "";
    height: var(--height-nav, 10px);
    // --height-nav:60px;
    left: var(--right-gradient-nav, 0);
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.3s 0s, visibility 0s 0s;
    visibility: visible;
    width: 3%;
    z-index: 1;
  }

  .tab-nav-left::before {
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, var(--surface-opacity-none)),
      rgba(var(--rgb-secondary-09), 0.2)
    );
    content: "";
    height: var(--height-nav, 10px);
    // --height-nav:60px;
    left: 0;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.3s 0s, visibility 0s 0s;

    visibility: visible;
    width: 3%;
    z-index: 1;
  }
}

.#{$prefix}tab {
  @include tab;
}
