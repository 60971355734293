@import "../../partial/scss/base";
.#{$prefix}radio {
  display: block;
  input[type="radio"] {
    // Remover o checkbox padrão do navegador
    opacity: 0;
    position: absolute;
    //Focus
    &:focus,
    &:focus-visible,
    &.focus-visible {
      &:checked + label::before,
      + label::before {
        @include focus-shadow;
      }
    }
    //hover
    &:hover:not(:disabled) {
      + label::before {
        background-image: linear-gradient(
          rgba(var(--interactive-rgb), var(--hover)),
          rgba(var(--interactive-rgb), var(--hover))
        );
      }
    }
    // Disabled
    &:disabled + label {
      @include disabled;
    }

    // Descritive Text
    + label {
      align-items: flex-start;
      color: v(color-secondary-08);
      cursor: pointer;
      display: inline-block;
      font-size: v(font-size-scale-base);
      font-weight: v(font-weight-medium);
      min-height: v(spacing-scale-3x);
      padding: v(spacing-scale-half) v(spacing-scale-4x) v(spacing-scale-base)
        v(spacing-scale-4x);
      padding-bottom: 0;
      position: relative;

      // Box
      &::before {
        background: v(color-secondary-01);
        border: 1px solid v(color-secondary-04);
        border-radius: 50%;
        content: "";
        height: v(spacing-scale-3x);
        left: 0;
        padding: v(spacing-scale-half) v(spacing-scale-base)
          v(spacing-scale-half) 0;
        position: absolute;
        width: v(spacing-scale-3x);
      }
    }
    /*** Elemento checado ***/
    &:checked + label::after {
      background: v(color-primary-lighten-01);
      border: 7px solid v(color-primary-lighten-01);
      border-radius: 50%;
      bottom: 0;
      content: "";
      height: 10px;
      left: 5px;
      position: absolute;
      right: 0;
      top: 9px;
      transform: scale(0.9, 0.9);
      width: 10px;
    }

    // Inválid
    &:invalid {
      + label::before {
        border-color: v(color-danger);
        border-width: 2px;
      }
    }
  }
  // Estados(States)
  @each $state, $color in (invalid, $color-danger), (valid, $color-success) {
    &.is-#{$state}:not(.is-inverted):not(.inverted):not([inverted]),
    &.#{$state}:not(.is-inverted):not(.inverted):not([inverted]),
    &[#{$state}]:not(.is-inverted):not(.inverted):not([inverted]) {
      input[type="radio"] {
        + label::before {
          border-color: $color;
          @if ($state == "invalid") {
            border-color: v(color-danger);
            border-width: 2px;
          }
          @if ($state == "valid") {
            border-color: v(color-success);
            border-width: 2px;
          }
        }
        // Checado
        &:checked + label::before {
          border-color: $color;
        }
        &:checked + label::after {
          //border-color: $color;
        }
        //focus
        &:focus {
          &:checked + label::before,
          + label::before {
            @include focus;
          }
        }
        // Hover
        &:hover:not(:disabled) + label::before {
          background-image: linear-gradient(
            rgba(var(--interactive-rgb), var(--hover)),
            rgba(var(--interactive-rgb), var(--hover))
          );
        }
      }
    }
  }

  // Versão pequena
  &.is-small,
  &.small,
  &[small] {
    input[type="radio"] {
      // Texto
      + label {
        line-height: v(spacing-scale-2xh);
        min-height: v(spacing-scale-2xh);
      }
      // Caixa
      + label::before {
        height: v(spacing-scale-2xh);
        width: v(spacing-scale-2xh);
      }
      // Checado
      &:checked + label::after {
        border-width: 4px;
        height: 10px;
        width: 10px;
      }
    }
  }
}
