@import "../../partial/scss/base";
.#{$prefix}wizard {
  height: calc(100vh - 52px);
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  .wizard-progress {
    background-color: v(color-secondary-02, $color-secondary-02);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); //Padrao horizontal
    grid-template-rows: none;
    min-height: 164px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: v(spacing-scale-7x);
    width: 100%;
    .wizard-progress-btn {
      background-color: transparent;
      border: none;
      box-shadow: none !important;
      color: v(color-primary-lighten-01, $color-primary-lighten-01);
      font-size: v(font-size-scale-up-01, $font-size-scale-up-01);
      line-height: 19px;
      max-height: 90px;
      padding-bottom: 0;
      padding-top: v(spacing-scale-2x);
      position: relative;
      text-indent: 0;
      transition-delay: 0s;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: linear;
      &:focus {
        outline: none;
      }
      &:focus:not([disabled]):not(.disabled)::before {
        outline-color: var(--focus-color);
        outline-offset: var(--focus-offset);
        outline-style: var(--focus-style);
        outline-width: var(--focus-width);
      }
      &:hover:not([disabled])::before{
        background-image: linear-gradient(
        rgba(var(--interactive-rgb), var(--hover)),
        rgba(var(--interactive-rgb), var(--hover))
        );
      }
      &:active:not([disabled])::before{
        background-image: linear-gradient(rgba(var(--interactive-rgb), var(--pressed)),
        rgba(var(--interactive-rgb), var(--pressed)));
      }
      &::before {
        background-color: v(color-secondary-01, $color-secondary-01);
        border: 2px solid v(color-primary-lighten-01, $color-primary-lighten-01);
        border-radius: 50%;
        box-sizing: border-box;
        color: v(color-primary-lighten-01, $color-primary-lighten-01);
        content: attr(step) !important;
        display: block;
        font-size: v(font-size-scale-up-02, $font-size-scale-up-02);
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
        height: 36px;
        left: 50%;
        line-height: 29px;
        margin-top: -13px;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        transition: all 0.15s linear 0s,
        -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s,
        transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s,
        transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s,
        -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        width: 36px;
        z-index: 3;
      }

      &::after {
        background-color: v(
        color-primary-lighten-01,
        $color-primary-lighten-01
        );
        content: "";
        display: block;
        height: 1px;
        left: calc(-50% + 34px / 2);
        position: absolute;
        top: 5px;
        transition-delay: 0s;
        transition-duration: 0.15s;
        transition-property: all;
        transition-timing-function: linear;
        width: calc(100% - 34px);
        z-index: 1;
      }

      &:first-child::after {
        display: none;
      }

      &[active] {
        color: var(--interactive);
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
      }

      &[active]::after{
        height: 2px;
      }

      &[active]::before {
        background-color: var(--interactive);
        border-color: var(--interactive);
        color: v(color-secondary-01, $color-secondary-01);
        font-weight: v(font-weight-bold, $font-weight-bold);
      }
    }
  }

  .wizard-form {
    position: relative;

    .wizard-panel {
      position: absolute;
      top: 0;
      left: 1%;
      height: 0;
      opacity: 0;
      visibility: hidden;
      border-top: 1px solid v(color-secondary-04, $color-secondary-04);
      border-bottom: 1px solid v(color-secondary-04, $color-secondary-04);
      margin-left: 2%;
      width: 96%;
      margin-bottom: v(spacing-scale-4x);
      &[active] {
        height: auto;
        opacity: 1;
        visibility: visible;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: linear;
        transition-delay: 0s;
        left: 0 !important;
      }
      .wizard-panel-content {
        min-height: calc(100vh - 300px);
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
      }
      .wizard-panel-btn {
        width: 104.5%;
        position: absolute;
        margin-top: 1px;
        min-height: 88px;
        height: auto;
        padding: v(spacing-scale-2x) 1.5%;
        background-color: v(color-secondary-02, $color-secondary-02);
        margin-left: -2.5%;
      }
      .wizard-btn-next,
      .wizard-btn,
      .wizard-btn-prev {
        float: right;
        margin-left: v(spacing-scale-2x);
      }

      .wizard-btn-canc {
        float: left;
      }
    }
  }

  &[vertical] {
    .wizard-progress {
      max-width: 260px;
      height: calc(100vh - 52px);
      float: left;
      grid-template-columns: 1fr; //Padrão vertical
      text-align: right;
      padding-top: 0;
      position: relative;

      .wizard-progress-btn {
        padding-top: 0;
        padding-bottom: 0px;
        line-height: 100%;
        text-align: right;
        padding-right: 70px;
        height: 100%;
        max-height: 100%;

        &::before {
          left: calc(100% - 32px);
          line-height: 26px;
          margin-top: calc(#{v(spacing-scale-2x)} * -1);
          position: absolute;
          text-align: center;
          top: 50%;
        }

        &:after {
          position: absolute;
          top: calc(-50% + 36px / 2);
          left: calc(100% - 33px);
          width: 1px;
          height: calc(100% - 34px);
        }
        &[active]::after {
          width: 2px;
        }
      }
    }
    &[scroll] {
      .wizard-progress {
        overflow-x: hidden;
        overflow-y: auto;
        .wizard-progress-btn {
          min-height: 100px;
        }
      }
    }
    .wizard-form {
      float: left;
      width: calc(100% - 260px);
      .wizard-panel {
        .wizard-panel-content {
          min-height: calc(100vh - 142px);
          max-height: calc(100vh - 142px);
        }
        .wizard-panel-btn {
          margin-left: -2%;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .wizard-form {
      .wizard-panel {
        border-top: 0;
        .wizard-panel-content {
          min-height: calc(100vh - 276px);
          max-height: calc(100vh - 276px);
          transition-property: all;
          transition-duration: 0.25s;
          transition-timing-function: linear;
          transition-delay: 0s;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      .wizard-panel-btn {
        width: 104%;
        margin-left: -2%;
        padding: 0 !important;
        background-color: transparent;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        //position: relative;
        .br-button {
          float: none;
          width: 90%;
          margin: v(spacing-scale-base) 5%;
        }
        .wizard-btn-next {
          order: 1;
        }
      }
    }
    .wizard-progress {
      position: relative;
      padding-top: v(spacing-scale-3x);
      min-height: 100px;
      max-height: 100px;
      transition-property: all;
      transition-duration: 0.25s;
      transition-timing-function: linear;
      transition-delay: 0s;
      overflow-y: hidden;
      .wizard-progress-btn {
        .info {
          font-size: v(font-size-scale-down-02, $font-size-scale-down-02);
          line-height: 1em;
          text-align: center;
        }
      }
      &:after {
        /* Ajuste Grip Lines
        position: absolute;
        display: block;
        width: auto;
        height: 1em;
        font-family: "Font Awesome 5 Free", sans-serif;
        font-size: var(--switch-icon-size);
        font-weight: var(--font-weight-black);
        color: v(color-primary-lighten-01, $color-primary-lighten-01);
        content: "\f52c";
        background-color: transparent; // v(color-secondary-04, $color-secondary-04);
        bottom: 5px;
        top: unset;
        left: 50%;
        margin-left: -0.5em;
        transition-property: all;
        transition-duration: 0.25s;
        transition-timing-function: linear;
        transition-delay: 0s;
        */
        display: block;
        position: absolute;

        width: 12%;
        height: 4px;
        border-radius: 2px;
        content: "";
        background-color: v(color-secondary-04, $color-secondary-04);
        bottom: 0;
        top: unset;
        left: 44%;
        transition-property: all;
        transition-duration: 0.25s;
        transition-timing-function: linear;
        transition-delay: 0s;
      }
    }

    &[collapsed] {
      .wizard-progress {
        max-height: 64px;
        min-height: 64px;
        overflow-y: hidden;
        padding-bottom: v(spacing-scale-3x);
        padding-top: v(spacing-scale-3x);

        &::after {
          margin-top: v(spacing-scale-4xh);
        }
        .wizard-progress-btn {
          padding-bottom: v(spacing-scale-base);
          .info {
            display: none;
          }
        }
      }
      .wizard-form {
        .wizard-panel {
          .wizard-panel-content {
            max-height: calc(100vh - 290px);
            min-height: calc(100vh - 290px);
            overflow-x: hidden;
            overflow-y: auto;
          }
        }
      }
    }

    &[vertical] {
      .wizard-progress {
        max-height: calc(100vh - 60px);
        max-width: 110px;
        &::after {
          height: 8%;
          left: unset;
          margin-top: 0;
          right: 0;
          top: 40%;
          width: 4px;
        }
        .wizard-progress-btn {
          line-height: 18px;
          padding-right: v(spacing-scale-7x);
          .info {
            line-height: 1.6em;
            margin-top: -0.5em;
            position: absolute;
            right: 54px;
          }
        }
      }
      .wizard-form {
        max-width: calc(100% - 110px);
        min-width: calc(100% - 110px);
        transition-property: all;
        transition-duration: 0.25s;
        transition-timing-function: linear;
        transition-delay: 0s;
        .wizard-panel {
          .wizard-panel-content {
            min-height: calc(100vh - 230px);
            max-height: calc(100vh - 230px);
          }
          .wizard-panel-btn {
            min-height: 119px;
          }
        }
      }
      &[collapsed] {
        .wizard-progress {
          max-width: 60px;
          padding-bottom: 0;
          padding-top: 0;
          &::after {
            height: 8%;
            left: unset;
            width: 4px;
            right: 0;
            top: 40%;
          }
          .wizard-progress-btn {
            padding-right: 0;
            .info {
              display: none;
            }
          }
        }
        .wizard-form {
          max-width: calc(100% - 60px);
          min-width: calc(100% - 60px);
        }
      }
    }
  }
}
