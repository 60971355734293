.template-base {
  display: grid;
  grid-template-areas: "skip-links" "header" "main" "footer";
  grid-template-rows: min-content min-content auto min-content;
  min-height: 100vh;

  @include media-breakpoint-up(sm) {
    #main {
      display: flex;

      > .container-lg {
        display: flex;
      }
    }

    #container-navigation {
      box-shadow: 6px 0 6px -3px rgba(0, 0, 0, 0.16);
      padding-top: 16px;
      .#{$prefix}menu {
        --menu-shadow: none;
        height: auto;
      }
    }
  }

  #skip-links {
    grid-area: skip-links;
  }
  #header {
    grid-area: header;
  }
  #main {
    grid-area: main;
  }
  #footer {
    grid-area: footer;
  }

  #skip-links {
    grid-area: skip-links;
  }

  #header {
    grid-area: header;
  }

  #main {
    grid-area: main;
  }

  #footer {
    grid-area: footer;
  }
}
