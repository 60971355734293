@import "~flatpickr/dist/flatpickr.min.css";

.#{$prefix}datetimepicker {
  .#{$prefix}input {
    input {
      &:hover {
        background: $color-primary-pastel-02;
      }
    }
  }
}

// Esconder "setas" do input.
input {
  appearance: none;
}

.flatpickr-calendar {
  box-shadow: 0 3px 6px rgba($color-secondary-09, 0.16);
  box-shadow: 0 3px 6px rgba($color-secondary-09, 0.16);
  box-shadow: 0 3px 6px rgba($color-secondary-09, 0.16);
  padding: 0 0 18px;
  width: 312px;
  &.open {
    width: 304px;
  }

  &.hasTime {
    &.open {
      &.noCalendar {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 116px;
        justify-content: center;
        padding: 0;
        width: 144px;
      }
      & .flatpickr-time {
        align-items: center;
        border: none;
        border-top: 1px solid v(color-secondary-04, #ccc);
        height: 100px;
        justify-content: center;
        line-height: 48px;
        margin: 1em 1em 0;
        max-height: none;
        width: 100%;
        & div.numInputWrapper {
          // display: flex;
          flex: none;
          flex-direction: row;
          justify-content: space-evenly;
          max-width: 50px;
        }
        & .arrowUp,
        & .arrowDown {
          @include hover;
          border: none;
          border-radius: 50%;
          color: var(--interactive);
          cursor: pointer;
          height: 25px;
          left: 12px;
          line-height: 25px;
          opacity: 1;
          padding: 0;
          width: 25px;
          &::after {
            border: solid var(--interactive);
            border-width: 0 3px 3px 0;
            content: "";
            height: 10px;
            left: 7px;
            position: absolute;
            top: 7px;
            transform: rotate(45deg);
            width: 10px;
          }
        }
        .arrowUp {
          top: -26px;
          &::after {
            // top: 9px;
            transform: rotate(225deg);
          }
          &:active {
            color: v(color-secondary-01);
          }
        }
        .arrowDown {
          bottom: -26px;
          top: unset;
          &:active {
            color: v(color-secondary-01);
          }
        }
        & .flatpickr-time-separator {
          height: 50px;
          margin: 0 13px;
        }
        input,
        .flatpickr-am-pm {
          background: v(color-secondary-01);
          border: 1px solid #888;
          border-radius: 6px;
          display: block;
          font-weight: bold;
          height: 40px;
          line-height: 40px;
          min-height: 40px;
          padding: 0;
          width: 47px;
          .numInput.flatpickr-minute {
            margin-left: 13px;
          }
        }
        .flatpickr-am-pm {
          @include hover;
          margin-left: 24px;
          &:active {
            color: v(color-secondary-01);
          }
        }
      }
      &.noCalendar {
        & > .flatpickr-time {
          border-top: none;
          margin: 0;
        }
      }
      input:focus,
      input:focus-visible,
      input.focus-visible {
        @include focus-shadow;
      }
    }
  }
  &::after,
  &::before {
    display: none;
  }
}
.flatpickr-months {
  padding-top: 16px;
  .flatpickr-month {
    height: 40px;
    .flatpickr-current-month {
      .flatpickr-monthDropdown-months {
        border: none;
        color: var(--interactive);
        font-size: v(font-size-scale-up-01);
        font-weight: v(font-weight-medium);
        margin-right: 13px;
        &:focus-visible {
          outline-color: var(--focus-color);
          outline-offset: var(--focus-offset);
          outline-style: var(--focus-style);
          outline-width: var(--focus-width);
        }
        .flatpickr-monthDropdown-month {
          background-color: transparent;
          color: var(--color-secondary-08);
          outline: none;
          padding: 0;
        }
        &:hover {
          background: transparent;
        }
      }
      .numInputWrapper {
        margin: 0 13px;
        // width: 62px;
        input.numInput.cur-year {
          border: 1px solid v(color-secondary-06);
          border-radius: 6px;
          color: v(color-secondary-08);
          font-size: v(font-size-scale-up-01);
          font-weight: v(font-weight-medium);
          padding: 0 8px;
          &:focus,
          &:focus-visible,
          &.focus-visible {
            @include focus-shadow;
          }
        }
        span {
          &.arrowUp,
          &.arrowDown {
            display: none;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .flatpickr-prev-month,
  .flatpickr-next-month {
    color: var(--interactive);
    fill: var(--interactive);
    svg {
      height: 1em;
    }
    &:hover svg {
      fill: var(--interactive);
    }
  }
  .flatpickr-prev-month.flatpickr-prev-month {
    left: 8px;
    top: 10px;
  }
  .flatpickr-next-month.flatpickr-next-month {
    right: 8px;
    top: 10px;
  }
}
.flatpickr-weekdays {
  padding: 0 33px;
  .flatpickr-weekday {
    color: v(color-secondary-07);
    font-size: v(font-size-scale-base);
    font-weight: v(font-weight-medium);
  }
}
.dayContainer {
  justify-content: flex-start;
  padding: 5px 27px;
  .flatpickr-day {
    align-items: center;
    color: var(--interactive);
    cursor: pointer;
    display: flex;
    font-size: v(font-size-scale-base);
    font-weight: v(font-weight-semi-bold);
    height: 24px;
    justify-content: center;
    line-height: 28px;
    margin: 3px 6px;
    max-width: 24px;
    padding: 0 1px;
    width: 24px;
    &:focus-visible {
      outline-color: var(--focus-color);
      outline-offset: var(--focus-offset);
      outline-style: var(--focus-style);
      outline-width: var(--focus-width);
    }
    &.flatpickr-disabled {
      @include disabled;
      color: v(status-disabled-text);
    }
    &.today {
      background: var(--color-support-11);
      border-radius: 16px;
      border-radius: 16px;
      border-radius: 16px;
      border-width: 0;
      color: var(--color-support-03);
      &:hover {
        background: var(--color-support-11);
        color: var(--color-support-03);
      }
      &.selected {
        color: var(--color-secondary-01);
      }
    }
    &.prevMonthDay,
    &.nextMonthDay {
      color: var(--interactive);
      font-weight: v(font-weight-medium);
      opacity: 0.6;
    }
    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
      background: var(--hover-effect);
      border-color: transparent;
    }
    &.selected,
    &.selected.endRange.inRange,
    &.selected.prevMonthDay:hover {
      background: var(--selected);
      border-width: 0;
      color: var(--color-secondary-01);
      opacity: 1;
    }
    &.selected.endRange.inRange {
      border-radius: 0 50px 50px 0;
    }
    &.inRange,
    &.inRange.today,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange {
      background: rgba(v(rgb-primary-lighten-01), 0.5);
      border-radius: 0;
      border-radius: 0;
      box-shadow: none;
      box-shadow: none;
      color: v(color-secondary-01);
      opacity: 1;
    }
  }
}
