@mixin textarea {
  @include focus;
  @include hover;
  background: v(color-secondary-01);
  border: $input-border-width solid $input-border-color;
  border-radius: $input-radius;
  box-sizing: border-box;
  color: v(color-secondary-07);
  display: block;
  font-size: v(font-size-scale-up-01);
  font-weight: v(font-weight-medium);
  padding: var(--padding-size);
  width: 100%;
}

@mixin textarea_state {
  /*** Estados ***/

  @each $state, $color in (success, v(color-success)), (danger, v(color-danger)),
    (warning, v(color-warning)), (info, v(color-info))
  {
    &.#{$state},
    &[data-#{$state}] {
      border: 2px solid $color;
      ~ .feedback.color-#{$state} {
        align-items: center;
        background: $color;
        color: var(--color-secondary-01) !important;
        display: inline-flex;
        height: 35px;
        margin-bottom: var(--spacing-scale-base);
        padding: 5px;
      }
      @if ($state == warning) {
        ~ .feedback.color-warning {
          color: var(--color-secondary-08) !important;
        }
      }
    }
  }
}

@mixin textarea_density {
  --textarea-small: var(--spacing-scale-base) var(--spacing-scale-2x)
    var(--spacing-scale-base) var(--spacing-scale-base);
  --textarea-medium: var(--spacing-scale-baseh) var(--spacing-scale-2x)
    var(--spacing-scale-baseh) var(--spacing-scale-baseh);
  --textarea-large: var(--spacing-scale-2x);
  --padding-size: var(--textarea-medium);

  @each $size in "small", "medium", "large" {
    &.#{$size},
    &[data-#{$size}] {
      --padding-size: var(--textarea-#{$size});
    }
  }
}
