@import "../../partial/scss/base";

@mixin onboarding {
  @include onboarding-tokens;
  @include onboarding-base;
  @include onboarding-bullets;
  @include onboarding-skip;
  @include onboarding-buttons;
  @include onboarding-tooltip;
  @include onboarding-floating;
}

@mixin onboarding-tokens {
  --onboarding-background: var(--bg-color);
  --onboarding-color: var(--color);
  --onboarding-padding: var(--spacing-scale-2x);
  --onboarding-title: var(--font-size-scale-up-01);
  --onboarding-font-size: var(--font-size-scale-base);
  --onboarding-font-weight: var(--font-weight-semi-bold);
  --onboarding-width: var(--onboarding-width-mobile);
  --onboarding-width-mobile: 90vw;
  --onboarding-width-tablet: 400px;
  --onboarding-tooltip-background: var(--color-warning);
  --onboarding-tooltip-color: inhrerit;
}

@mixin onboarding-base {
  width: var(--onboarding-width);
  max-width: var(--onboarding-width-tablet);
  @include media-breakpoint-up(sm) {
    --onboarding-width: var(--onboarding-width-tablet);
  }
}

@mixin onboarding-buttons {
  .introjs-tooltipbuttons {
    border: 0;
    position: relative;
    text-align: center;
    margin: 0 var(--onboarding-padding);
    padding: 0;
  }
  .introjs-button {
    --pressed: 0;
    --button-size: var(--button-medium);
    @include button-tokens;
    @include button-base;
    &:not(.introjs-disabled) {
      @include focus;
      @include hover;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .introjs-nextbutton,
  .introjs-prevbutton {
    --button-radius: 50%;
    --button-padding: 0;
    position: absolute;
    top: calc(var(--onboarding-padding) * -5.5);
    width: var(--button-size);
  }
  .introjs-prevbutton {
    left: 0;
  }
  .introjs-nextbutton {
    right: 0;
  }
  .introjs-donebutton {
    --interactive-rgb: var(--rgb-secondary-01);
    --button-background: var(--color-primary-default);
    --button-color: var(--color-secondary-01);
    --button-padding: 0 var(--spacing-scale-3x);
    --button-radius: 100em;
    text-shadow: none;
    width: auto;
    position: static;
    float: none;
    margin-bottom: var(--onboarding-padding);
  }
  .introjs-disabled {
    @include disabled;
  }
}

@mixin onboarding-bullets {
  .introjs-bullets {
    ul {
      li {
        a {
          background: var(--off);
          opacity: var(--disabled);
          position: relative;
          z-index: 1;
          &.active {
            background: var(--color-primary-lighten-01);
            opacity: 1;
            width: 6px;
          }
        }
      }
    }
  }
}

@mixin onboarding-skip {
  .introjs-tooltip-header {
    .introjs-skipbutton {
      visibility: hidden;
      position: absolute;
      z-index: 0;
    }
  }
  .introjs-skip {
    margin-bottom: var(--onboarding-padding);
    text-align: center;
    position: relative;
    z-index: 1;
  }
}

@mixin onboarding-tooltip {
  background: var(--onboarding-tooltip-background);
  color: var(--onboarding-tooltip-color);
  .introjs-arrow {
    &.left-bottom {
      border-right-color: var(--onboarding-tooltip-background);
    }
    &.right-bottom {
      border-left-color: var(--onboarding-tooltip-background);
    }
    &.top,
    &.top-middle,
    &.top-right {
      border-bottom-color: var(--onboarding-tooltip-background);
    }
    &.bottom {
      border-top-color: var(--onboarding-tooltip-background);
    }
  }
}

@mixin onboarding-floating {
  &.introjs-floating {
    background: var(--onboarding-background);
    color: var(--onboarding-color);
    .introjs-nextbutton {
      --interactive-rgb: var(--rgb-secondary-01);
      --button-background: var(--color-primary-default);
      --button-color: var(--color-secondary-01);
    }
    .introjs-prevbutton {
      --button-background: var(--color-secondary-01);
      --button-border: var(--color-primary-default);
    }
  }
}

.introjs-tooltip {
  @include onboarding;
}
