@mixin tabela {
  @include tabela-tokens;
  @include tabela-base;
  @include tabela-headers;
  @include tabela-header;
  @include tabela-top-bar;
  @include tabela-responsive;
  @include tabela-search;
  @include tabela-selection-bar;
  @include tabela-selection;
  @include tabela-footer;
  @include tabela-density;
}

@mixin tabela-tokens {
  --table-border: 1px solid var(--color-secondary-04);
  --table-color: var(--text-color);
  --table-padding: var(--spacing-scale-3x);
  --table-shadow: var(--surface-shadow-sm);
  --table-font-weight: var(--font-weight-medium);
  --table-header-background: var(--color-secondary-03);
  --table-header-font-weight: var(--font-weight-semi-bold);
  --table-row-size: var(--table-row-medium);
  --table-row-small: var(--spacing-scale-base);
  --table-row-medium: var(--spacing-scale-2x);
  --table-row-large: var(--spacing-scale-3x);
}

@mixin tabela-base {
  // Mobile
  @include tabela-card;
  .dropdown {
    [data-toggle="dropdown"] {
      + * {
        left: auto;
        right: 0;
      }
    }
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    @include tabela-desktop;
  }
}

@mixin tabela-card {
  table {
    --table-border: 0;
    --table-padding: var(--spacing-scale-2x);
  }
  caption {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  td {
    display: block;
    padding-left: var(--table-padding);
    padding-right: var(--table-padding);
    &::before {
      --table-color: var(--color-primary-darken-01);
      --table-font-weight: 700;
      color: var(--table-color);
      content: attr(data-th);
      display: block;
      font-weight: var(--table-font-weight);
    }
  }
  tr {
    box-shadow: var(--table-shadow);
    display: block;
    margin: var(--table-padding) 0;
  }
  thead {
    display: none;
  }
  tbody {
    display: block;
    .is-selected td,
    .is-selected tr,
    .is-selected td::before {
      background-color: v(color-primary-lighten-01);
      color: v(color-secondary-01);

    }
    .is-selected td{
      input + label:before{
        --focus-color: var(--color-support-10);
      }
    }
  }
}

@mixin tabela-desktop {
  table {
    --table-border: 1px solid var(--color-secondary-04);
    --table-padding: var(--spacing-scale-3x);
    --table-shadow: none;
  }
  td {
    &::before {
      content: none;
    }
  }
  th,
  td {
    display: table-cell;
    padding: var(--table-row-size) calc(var(--table-padding) * 0.5);
    &:first-child {
      padding-left: var(--table-padding);
    }
    &:last-child {
      padding-right: var(--table-padding);
    }
  }
  tr {
    display: table-row;
    margin: 0;
  }
  thead {
    display: table-header-group;
  }
  tbody {
    display: table-row-group;
  }
}

@mixin tabela-header {
  // Mobile
  .header,
  .table-header {
    box-shadow: var(--table-shadow);
    order: 0;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .header,
    .table-header {
      --table-shadow: none;
    }
  }
}

@mixin tabela-headers {
  // Mobile
  .headers,
  .clone-headers {
    display: none;
  }
  .syncscroll {
    overflow: visible;
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .headers,
    .clone-headers {
      background: var(--table-header-background);
      display: block;
      overflow-y: hidden;
      position: sticky;
      top: 0;
      z-index: 1;
      .scroller {
        align-items: center;
        display: flex;
        overflow-x: hidden;
      }
      .item {
        font-weight: var(--table-header-font-weight);
        padding: var(--table-row-size) calc(var(--table-padding) * 0.5);
        &:first-child {
          padding-left: var(--table-padding);
        }
        &:last-child {
          padding-right: var(--table-padding);
        }
        .br-checkbox {
          margin-bottom: 0;
        }
      }
    }
    .syncscroll {
      overflow: auto;
    }
  }
}

@mixin tabela-top-bar {
  // Mobile
  .top-bar {
    --table-padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    align-items: center;
    background-color: var(--color-secondary-03);
    display: flex;
    padding: var(--table-padding);
    .title,
    .table-title {
      flex-grow: 1;
      font-size: var(--font-size-scale-up-02);
      font-weight: 600;
    }
    .actions-trigger {
      margin-right: var(--spacing-scale-base);
    }
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .top-bar {
      background-color: v(color-secondary-01, $color-secondary-01);
      title {
        font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
        line-height: v(font-line-height-medium, $font-line-height-medium);
      }
    }
  }
}

@mixin tabela-responsive {
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .responsive {
      overflow: auto;
    }
  }
}

@mixin tabela-search {
  .search-bar {
    --table-padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    align-items: center;
    background-color: var(--table-header-background);
    display: none;
    left: 0;
    padding: var(--table-padding);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    &.show {
      display: flex;
    }
    .#{$prefix}input {
      flex: 1;
      label {
        display: none;
      }
      input {
        + .#{$prefix}button {
          --button-size: var(--button-small);
          bottom: var(--spacing-scale-half);
          position: absolute;
          right: var(--spacing-scale-half);
          top: auto;
        }
      }
      + .#{$prefix}button {
        margin-left: var(--table-row-medium);
      }
    }
  }
  @media (min-width: $grid-breakpoint-sm) {
    .search-bar {
      --table-header-background: var(--bg-color);
    }
  }
}

@mixin tabela-selection-bar {
  // Mobile
  .selected-bar,
  .selection-bar {
    --table-padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    align-items: center;
    background-color: var(--interactive);
    bottom: 0;
    display: none;
    left: 0;
    padding: var(--table-padding);
    right: 0;
    top: 0;
    &.show {
      display: flex;
    }
    .info {
      align-items: center;
      display: flex;
      flex-grow: 1;
      white-space: nowrap;

      [data-toggle="check-all"],
      .select-all {
        font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
        margin-right: v(spacing-scale-half);
        padding: 0;
      }
    }
    .text,
    .count {
      color: v(color-secondary-01, $color-secondary-01);
      line-height: v(font-line-height-low, $font-line-height-low);
      padding-right: v(spacing-scale-half);
    }
    .mobile {
      display: inline;
    }
    &.is-active,
    &.active {
      opacity: 1;
      top: 0;
    }
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .selected-bar,
    .selection-bar {
      .mobile {
        display: none;
      }
      .action-button {
        button {
          min-width: auto;
          width: auto;
          span {
            display: inline;
            padding-left: v(spacing-scale-3x);
            padding-right: v(spacing-scale-base);
          }
        }
      }
    }
  }
}

@mixin tabela-selection {
  --checkbox-size: 24px;

  // Mobile
  &[data-selection] {
    tbody {
      tr {
        position: relative;
      }
      td {
        padding-left: calc(var(--table-padding) * 2 + var(--checkbox-size));
        &:first-child {
          left: var(--table-padding);
          padding-left: 0;
          padding-right: 0;
          position: absolute;
          width: var(--checkbox-size);
        }
      }
    }
    .#{$prefix}checkbox {
      line-height: 1;

      input {
        line-height: 1;
      }
      label {
        line-height: 1;
        --focus-color: var(--color-support-03);
        &:empty {
          padding-left: var(--checkbox-size);
          padding-right: 0;
        }
      }
    }
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    &[data-selection] {
      tbody {
        tr {
          position: static;
        }
        td {
          padding-left: calc(var(--table-padding) * 0.5);
          &:first-child {
            padding-left: var(--table-padding);
            padding-right: calc(var(--table-padding) * 0.5);
            position: static;
          }
        }
      }
    }
  }
}

@mixin tabela-footer {
  // Mobile
  .footer,
  .table-footer {
    background: var(--bg-color);
    bottom: 0;
    box-shadow: 0 -3px 6px rgba(var(--rgb-secondary-09), 0.16);
    padding: var(--spacing-scale-2x);
    position: sticky;
    .#{$prefix}input {
      .#{$prefix}button {
        transform: rotate(180deg);
      }
    }
    .#{$prefix}list {
      bottom: 100%;
    }
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .footer,
    .table-footer {
      box-shadow: none;
      position: static;
    }
  }
}

@mixin tabela-density {
  @each $size in small, medium, large {
    &.#{$size} {
      table,
      .headers,
      .clone-headers .item {
        --table-row-size: var(--table-row-#{$size});
      }
    }
  }
}

.#{$prefix}table {
  @include tabela;
}
