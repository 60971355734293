@mixin pagination {
  @include pagination-tokens;
  @include pagination-base;
  @include pagination-page;
  @include pagination-sizes;
  @include pagination-ellipsis;
  @include pagination-arrows;
  @include pagination-go-to-page;
  @include pagination-per-page;
  @include pagination-information;
}

@mixin pagination-tokens {
  --pagination-background: var(--bg-color);
  --pagination-margin: var(--spacing-scale-base);
  --pagination-select-width: 100px;
  --pagination-size: var(--pagination-medium);
  --pagination-small: 24px;
  --pagination-medium: 32px;
  --pagination-large: 40px;
}

@mixin pagination-base {
  color: var(--text-color);
  display: flex;
  justify-content: center;
  ul {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
  }
  &.inverted {
    --bg-color: var(--color-primary-darken-01);
    --text-color: var(--color-secondary-01);
    --interactive: var(--color-secondary-01);
    --active: var(--interactive);
    --interactive-rgb: var(--rgb-secondary-01);
    --hover: var(--surface-opacity-sm, 0.3);
    --focus-color: var(--color-support-10);
  }
  // Alinhamento do botão select
  .#{$prefix}select {
    .#{$prefix}input {
      .#{$prefix}button.circle {
        bottom: auto;
        right: var(--spacing-scale-half);
        top: auto;
      }
    }
  }
  // Direção do divider
  .#{$prefix}divider {
    border-right-width: var(--divider-size);
    border-top: 0;
    // border-right: 1px solid var(--color-secondary-04);
    // border-top: 0;
    // width: 1px;
  }
}

@mixin pagination-page {
  .page {
    @include hover;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 100em;
    color: var(--interactive);
    display: inline-flex;
    justify-content: center;
    margin: 0 calc(var(--pagination-margin) * 0.5);
    min-height: var(--pagination-size);
    min-width: var(--pagination-size);
    padding: 0 var(--spacing-scale-base);
    white-space: nowrap;
    &.active {
      --hover: var(--surface-opacity-sm, 0.3);
      --interactive-rgb: var(--rgb-secondary-01);
      background: var(--active);
      color: var(--pagination-background);
    }
  }
  [data-previous-page],
  [data-next-page] {
    margin: 0 calc(var(--pagination-margin) * 0.5);
  }
  &.inverted {
    .page {
      &.active {
        --interactive-rgb: var(--rgb-primary-darken-01);
      }
    }
  }
}

@mixin pagination-sizes {
  @each $size in small, medium, large {
    &.#{$size} {
      --pagination-size: var(--pagination-#{$size});
    }
  }
}

@mixin pagination-select {
  align-items: center;
  display: flex;
  .#{$prefix}input {
    align-items: center;
    display: flex;
    label {
      font-weight: var(--font-weight);
      margin-right: var(--spacing-scale-base);
    }
    input {
      width: var(--pagination-select-width);
    }
    .#{$prefix}button {
      bottom: auto;
      right: var(--spacing-scale-half);
      top: auto;
    }
  }
  .#{$prefix}list {
    right: 0;
    width: var(--pagination-select-width);
  }
  .#{$prefix}item {
    @include hover;
  }
}

@mixin pagination-ellipsis {
  .pagination-ellipsis {
    margin: 0 calc(var(--pagination-margin) * 0.5);
    position: relative;
    .#{$prefix}button {
      --button-size: var(--pagination-size);
    }
    .#{$prefix}list {
      max-height: 150px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: v(color-secondary-03);
      }
      &::-webkit-scrollbar-thumb {
        background: v(color-secondary-06);
      }
      &:not([hidden]) {
        display: block;
      }
    }
  }
}

@mixin pagination-arrows {
  .pagination-arrows {
    align-items: center;
    display: flex;
  }
}

@mixin pagination-go-to-page {
  .pagination-go-to-page {
    @include pagination-select;
  }
}

@mixin pagination-per-page {
  .pagination-per-page {
    @include pagination-select;
  }
}

@mixin pagination-information {
  .pagination-information {
    align-items: center;
    display: flex;
  }
}

.#{$prefix}pagination {
  @include pagination;
}
