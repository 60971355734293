// Grid Bootstrap
@import "~bootstrap/scss/grid";

@include media-breakpoint-up(sm) {
  .container,
  .container-fluid {
    padding-left: $grid-tablet-margin;
    padding-right: $grid-tablet-margin;
  }

  .row {
    margin-left: -($grid-tablet-gutter / 2);
    margin-right: -($grid-tablet-gutter / 2);
  }

  .col,
  [class*="col-"] {
    padding-left: $grid-tablet-gutter / 2;
    padding-right: $grid-tablet-gutter / 2;
  }
}

@include media-breakpoint-up(xl) {
  .row {
    margin-left: -$grid-tv-gutter / 2;
    margin-right: -$grid-tv-gutter / 2;
  }

  .col,
  [class*="col-"] {
    padding-left: $grid-tv-gutter / 2;
    padding-right: $grid-tv-gutter / 2;
  }
}
