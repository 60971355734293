.#{$prefix}input {
  @include input_state;
  position: relative;
  label {
    display: inline-block;
    padding-bottom: v(spacing-scale-half);
  }
  &.has-icon {
    .#{$prefix}button.circle {
      border-bottom: v(spacing-scale-base);
      border-right: v(spacing-scale-half);
      border-top: v(spacing-scale-base);
    }
  }
  input {
    @include input;
    @include input_density;
    &.disabled,
    &[disabled] {
      background-color: v(color-secondary-03);
      border: 1px solid v(color-secondary-06);
      color: v(color-secondary-06);
      ~ button {
        &.icon {
          color: v(color-secondary-06);
          cursor: not-allowed;
        }
      }
      ~ .help {
        display: none;
      }
    }
    & + .#{$prefix}button {
      position: absolute;
      right: var(--spacing-scale-half);
      top: 28px;
    }
  }
  // TODO: Remover [1] após revisão do feedback e help
  // 1 - temporário até remover feedback e help de dentro do input
  button {
    &:not(.#{$prefix}button) {
      background-color: transparent;
    }
    &.icon {
      --focus-offset: calc(var(--spacing-scale-half) * -1);
      @include hover;
      @include focus;
      background: transparent;
      border: 0;
      bottom: 0;
      color: var(--interactive);
      font-size: $input-font-size;
      height: $input-default;
      margin: 0;
      min-width: 0;
      outline: none;
      padding: 0 v(spacing-scale-2x);
      position: absolute;
      right: 0;
      top: 1.5em; // 1
      width: auto;
      img {
        max-width: 24px;
        min-width: 24px;
        transform: translate(0, -4px);
      }
    }
  }
  ~ .help {
    font-size: v(font-size-scale-down-01);
  }
  &.is-filter,
  &[data-filter] {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    label {
      margin-right: v(spacing-scale-2x);
      white-space: nowrap;
    }
    input {
      min-height: $input-small;
    }
  }
}
