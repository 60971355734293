[data-toggle="dropdown"] {
  + * {
    position: absolute;
    &:not(.show) {
      display: none;
    }
  }
}

.dropdown {
  position: relative;
  [data-toggle="dropdown"] {
    + * {
      box-shadow: var(--surface-shadow-sm);
      left: 0;
      position: absolute;
      top: 100%;
    }
  }
  > .#{$prefix}notification {
    left: auto;
    right: 0;
  }
}
