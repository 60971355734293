@mixin scrim {
  &.foco {
    background: var(--surface-overlay-scrim);
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;

    .br-modal {
      background-color: v(color-secondary-01, $color-secondary-01);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 1000;
      padding: var(--spacing-scale-2x);
    }

    &.active {
      display: block;
    }
  }

  &.inibicao {
    position: relative;

    &::before {
      background: var(--surface-overlay-scrim);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &.legibilidade {
    position: relative;

    .scrim-text {
      background: var(--surface-overlay-text);
      bottom: 0;
      left: 0;
      padding: v(spacing-scale-3x) v(spacing-scale-baseh);
      position: absolute;
      width: 100%;
    }
  }
}
