.#{$prefix}select {
  --select-divider: 1px solid var(--color-secondary-04);
  max-width: 400px;
  min-width: 100px;
  position: relative;
  .#{$prefix}input {
    .#{$prefix}button {
      &.circle,
      &.small {
        bottom: 0.3em;
        outline: none;
        position: absolute;
        right: 0.4em;
        top: auto;
      }
    }
  }
  .#{$prefix}list {
    background: v(color-secondary-01);
    box-shadow: 0 3px 6px rgba(v(rgb-secondary-09), 0.16);
    cursor: pointer;
    display: none;
    height: auto;
    max-height: 560px;
    overflow: auto;
    position: absolute;
    resize: none;
    width: 100%;
    z-index: 100;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: v(color-secondary-03);
    }
    &::-webkit-scrollbar-thumb {
      background: v(color-secondary-06);
    }
    &:focus,
    &:active {
      color: v(color-secondary-09);
      outline: none;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: v(color-secondary-07);
      }
    }
    &[expanded] {
      display: block;
    }
  }
  .#{$prefix}item {
    --item-padding: var(--spacing-scale-2x);
    padding: 0;
    .content {
      padding: 0;
    }
    &:not(:last-child) {
      border-bottom: var(--select-divider);
    }
    &:not([disabled]) {
      --interactive-rgb: var(--rgb-secondary-09);
      --focus-offset: calc(var(--spacing-scale-half) * -1);
      @include focus;
      @include hover;
    }
    &.selected,
    &[selected] {
      @include focus;
      @include hover;
    }
    &.highlighted,
    &[highlighted] {
      &:not(.selected):not([selected]) {
        background: rgba(v(rgb-support-03), 0.16);
        .#{$prefix}checkbox,
        .#{$prefix}radio {
          label {
            color: var(--color-support-03);
          }
          &:hover{
            background: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
            label {
              color: var(--text-color);
            }
          }
        }

      }
    }
  }
  .#{$prefix}checkbox {
    input[type="checkbox"] {
      + label {
        align-items: center;
        display: flex;
        padding: var(--item-padding);
        padding-left: calc(var(--checkbox-size) + var(--item-padding) + var(--spacing-scale-base));
        &::before {
          margin-left: var(--item-padding);
          outline: none;
        }
        &::after {
          margin-left: var(--item-padding);
          margin-top: 14px;
        }
      } 
    }
  }
  .#{$prefix}radio {
    input[type="radio"] {
      + label {
        color: var(--text-color);
        display: block;
        height: auto;
        min-height: 0;
        padding: var(--item-padding);
        &::before,
        &::after {
          content: none;
        }
      }
    }
  }
}
