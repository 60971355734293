// Desabilitado
@mixin disabled {
  cursor: not-allowed;
  opacity: var(--disabled);

  * {
    pointer-events: none;
  }
}

// Hover
@mixin hover {
  &:not(:disabled) {
    &:hover {
      background-image: linear-gradient(
        rgba(var(--interactive-rgb), var(--hover)),
        rgba(var(--interactive-rgb), var(--hover))
      );
    }
    &:active {
      background-image: linear-gradient(
        rgba(var(--interactive-rgb), var(--pressed)),
        rgba(var(--interactive-rgb), var(--pressed))
      );
    }
  }
}

// Focus
@mixin focus {
  &:focus {
    outline: none;
  }

  &.focus-visible,
  &:focus-visible {
    outline-color: var(--focus-color);
    outline-offset: var(--focus-offset);
    outline-style: var(--focus-style);
    outline-width: var(--focus-width);
  }
}

@mixin focus-shadow {
  border-color: var(--focus-color);
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus-color);
  outline: none;
}

// Ativo
@mixin active {
  background: var(--active);
  color: var(--color-secondary-01);
}

// Dark mode
@mixin dark-mode {
  --text-color: var(--color-secondary-01);
  --interactive: var(--color-primary-pastel-01);
  --interactive-rgb: var(--rgb-primary-pastel-01);
  --visited: var(--color-secondary-04);
  --hover: var(--surface-opacity-sm, 0.3);
  --pressed: var(--surface-opacity-lg, 0.65);
  --focus-color: var(--color-support-10);
  --active: var(--color-secondary-01);
}
