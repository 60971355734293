@import "../../partial/scss/base";
$diameter: 84px;
$position: calc(50% - (84px / 2));
$diameter-md: 44px;
$position-md: calc(50% - (44px / 2));
$diameter-sm: 24px;
$position-sm: calc(50% - (24px / 2));
$rotationSteps: 100;
$rotationIncrement: (180 / $rotationSteps);
$stroke: 5px;

.rotulo {
  font-size: v(font-size-scale-base);
  font-weight: v(font-weight-medium);
  color: v(color-secondary-08);
}
* {
  &.is-loading,
  &.loading,
  &[loading] {
    color: transparent !important;
    min-height: 24px;
    pointer-events: none;
    position: relative;
    &::after {
      animation: spinAround 1.3s infinite linear;
      border: 2px solid var(--interactive);
      border-radius: 50%;
      border-right-color: transparent;
      content: "";
      display: block;
      height: $diameter-sm;
      left: $position-sm;
      position: absolute;
      top: $position-sm;
      width: $diameter-sm;
    }
    &.primary {
      &::after {
        border-bottom-color: v(color-secondary-01);
        border-top-color: v(color-secondary-01);
        border-left-color: v(color-secondary-01);
      }
    }
    &.medium,
    &[md] {
      &::after {
        border-width: 4px;
        height: $diameter-md;
        left: $position-md;
        top: $position-md;
        width: $diameter-md;
      }
    }
  }
}

.#{$prefix}loading {
  background-color: v(color-secondary-04);
  border-radius: 50%;
  height: $diameter;
  width: $diameter;
  margin: v(spacing-scale-3xh);
  &-mask,
  &-fill {
    backface-visibility: hidden;
    border-radius: 50%;
    clip: rect(0, $diameter, $diameter, $diameter / 2);
    height: $diameter;
    overflow: hidden;
    position: absolute;
    width: $diameter;
  }
  &-fill {
    background: var(--interactive);
    clip: rect(0, $diameter / 2, $diameter, 0);
  }
  &:after {
    background: v(color-secondary-01);
    border-radius: 50%;
    color: var(--interactive);
    content: attr(data-progress) "%";
    $diameter: $diameter - $stroke * 2;
    font-size: v(font-size-scale-up-02);
    font-weight: v(font-weight-semi-bold);
    height: $diameter;
    line-height: $diameter;
    margin: $stroke;
    position: absolute;
    text-align: center;
    width: $diameter;
  }
  @keyframes fill {
    0% {
      transform: rotate(0deg);
    }
    @for $step from 1 through $rotationSteps {
      $rotation: ($step/100) * 360;
      // TODO: Verificar erro ao compilar com dart scss
      // [data-progress=#{$step}]% {
      //   transform: rotate($rotation#{deg});
      // }
    }
  }
  @for $step from 1 through $rotationSteps {
    $rotation: ($step/100) * 360;
    &[data-progress="#{$step}"] {
      .#{$prefix}loading-mask.full,
      .#{$prefix}loading-fill {
        transform: rotate($step * $rotationIncrement#{deg});
        animation: fill ease-in-out 2s;
      }
    }
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
